import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap"

export default class Covid extends React.Component {
  constructor() {
    super()
    this.state = {
      states: "",
      district: "",
      listOfState: [],
      listOfDistrict: [],
      calenderByDistrict: [],
      error: null,
    }
  }

  handleStates = data => {
    this.setState({
      listOfState: data.states,
    })
  }

  handleDistrict = data => {
    this.setState({
      listOfDistrict: data.districts,
    })
  }

  // onSubmit = event => {
  //   this.setState({
  //     calenderByDistrict: event.sessions,
  //   })
  //  event.preventDefault()
  // }

  handleCalenderByDistrict = data => {
    this.setState({
      calenderByDistrict: data.sessions,
    })
    
  }

  //Do we need to define global variable and save the id like state id to find the district and so on ?
  // Need to investigate this

  componentDidMount() {
    let url = "https://cdn-api.co-vin.in/api/v2/admin/location/states"
    let districtUrl =
      "https://cdn-api.co-vin.in/api/v2/admin/location/districts/5"
    let calenderByDistrictUrl =
      "https://cdn-api.co-vin.in/api/v2/appointment/sessions/public/findByDistrict?district_id=512&date=31-03-2021"
    fetch(url)
      .then(res => res.json())
      .then(this.handleStates)
      .catch()

    fetch(districtUrl)
      .then(res => res.json())
      .then(this.handleDistrict)
      .catch()

    fetch(calenderByDistrictUrl)
      .then(res => res.json())
      .then(this.handleCalenderByDistrict)
      .catch()
  }
  render() {
    return (
      <Layout>
        <SEO title="Vaccinate Me" />
        <h1>Vaccination Slots</h1>
        
        <div>
          <Form className="text-center">
            <FormGroup row>
              <Col sm={4}>
                <select className="form-control mb-3">
                  {this.state.listOfState.map(item => (
                    <option
                      key={item.state_id}
                      value={item.state_name}
                    >
                      {item.state_name}
                    </option>
                  ))}
                </select>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={4}>
                <select className="form-control mb-3">
                  {this.state.listOfDistrict.map(item => (
                    <option key={item.district_id} value={item.district_name}>
                      {item.district_name}
                    </option>
                  ))}
                </select>
              </Col>
            </FormGroup>

            

            {this.state.calenderByDistrict.map(item => (
                    <option key={item.center_id} value={item.name}>
                      {item.name} {item.min_age_limit} {item.date} {item.vaccine} {item.slots}
                    </option>
                  ))}
            
            {/* <FormGroup check row>
              <Col sm={{ size: 2, offset: 2 }}>
                <center>
                  <button
                    className="btn btn-outline-success"
                    onClick={this.handleCalenderByDistrict}> 
                  
                    Search Slot
                  </button>
                 

                </center>
              </Col>
            </FormGroup> */}

            
          </Form>
        </div>
      </Layout>
    )
  }
}

//https://www.youtube.com/watch?v=xg7NRRdBWzg
